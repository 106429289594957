export function LeaveScreen({ setIsMeetingLeft }) {
  return (
    <div className="bg-gray-800 h-screen flex flex-col flex-1 items-center justify-center">
      <h1 className="text-white text-4xl">You left the meeting!</h1>
	  <tr>
	  <td>
      <div className="mt-12">
        <button
          className="`w-full bg-purple-350 text-white px-16 py-3 rounded-lg text-sm"
          onClick={() => {
            setIsMeetingLeft(false);
          }}
        >
          Rejoin the Meeting
        </button>
      </div>
	  </td>
	  <td>
	  <div>
	   <button  className="`w-full bg-purple-350 text-white px-16 py-3 rounded-lg text-sm"
         onClick={() =>{ 
    window.open("about:blank", "_self");
    window.close();
	 window.close("about:blank", "_self");}}> Exit the Meeting
          </button>

	  </div>
	  </td>
  </tr>
    </div>
  );
}
