
import queryString from 'query-string'
const API_BASE_URL = "https://api.videosdk.live";
const VIDEOSDK_TOKEN = process.env.REACT_APP_VIDEOSDK_TOKEN;
const API_AUTH_URL = process.env.REACT_APP_AUTH_URL;

const requester = queryString.parse(window.location.search.replace(/^.*\?/, '')).requester;
console.log(requester);
const appointmentId = queryString.parse(window.location.search.replace(/^.*\?/, '')).appointmentId;
export const getToken = async () => {
  if (VIDEOSDK_TOKEN && API_AUTH_URL) {
    console.error(
      "Error: Provide only ONE PARAMETER - either Token or Auth API"
    );
  } else if (VIDEOSDK_TOKEN) {
    return VIDEOSDK_TOKEN;
  } else if (API_AUTH_URL) 
	{
		let res;
	  if(requester != null){
    res = await fetch(`${API_AUTH_URL}/get-token?requester=`+requester, {
      method: "GET",
    });
	  } else {
		   res = await fetch(`${API_AUTH_URL}/get-token?appointmentId=`+appointmentId, {
      method: "GET",
    });

	  }
		if(res.status === 500){
                    console.log("Got Error from Auth Server...",res.message);
                        return res.message;
                }
		else{
    const { token } = await res.json();

    return token;
		}
  } else {
    console.error("Error: ", Error("Please add a token or Auth Server URL"));
  }
};

export const createMeeting = async ({ token }) => {
  const url = `${API_BASE_URL}/v2/rooms`;
  const options = {
    method: "POST",
    headers: { Authorization: token, "Content-Type": "application/json" },
  };
if(token !== undefined) {
  const { roomId } = await fetch(url, options)
    .then((response) => response.json())
    .catch((error) => console.error("error", error));

  return roomId;
} else {
   throw new Error( "Error in authentication server");
}
};

export const validateMeeting = async ({ roomId, token }) => {
  const url = `${API_BASE_URL}/v2/rooms/validate/${roomId}`;

  const options = {
    method: "GET",
    headers: { Authorization: token, "Content-Type": "application/json" },
  };

  const result = await fetch(url, options)
    .then((response) => response.json()) //result will have meeting id
    .catch((error) => console.error("error", error));

  return result ? result.roomId === roomId : false;
};
